<template>
    <div class="receiveInterview-container">
        <BreadcrumbNav :page-name="$t('myInterview.myInterviewDetail')"/>
        <div class="main">
            <div class="people-wrap">
                <div class="people-info fl">
                    <!-- <img class="head-icon fl" :src="details_data.initiator_avatar" alt="" /> -->
                    <img :src="details_data.initiator_avatar" alt="" class="head-icon fl"/>
                    <div class="people-info-text fl">
                        <h1 class="over">{{ details_data.initiator_name }}</h1>
                        <h2 class="over">
                            {{ details_data.company_name }} · {{ details_data.position }}
                        </h2>
                    </div>
                </div>
                <div class="btn-group fl">
                    <CustomButton @click.native="$router.push({
                        path: '/user/index',query:{userType:2,talentId:details_data.initiator_id,}})" class="fl"
                                  type="black">
                        {{ $t("project.buyNow") }}<!--立即购买-->
                    </CustomButton>
                    <CustomButton
                            @click.native="attention(details_data.followed, details_data.initiator_id, details_data.initiator_name)"
                            class="fl" style="margin-left: 26px"
                            type="black"
                            v-if="this.details_data.followed == 0">{{$t('myProject.immediateAttention')}}<!--立即关注-->
                    </CustomButton>
                    <CustomButton
                            @click.native="attention(details_data.followed, details_data.initiator_id, details_data.initiator_name)"
                            class="fl" style="margin-left: 26px"
                            type="white"
                            v-else>{{$t('myProject.unfollow')}}<!--取消关注-->
                    </CustomButton>
                </div>
            </div>
            <!--//待推荐人反馈-->
            <div class="reason" v-if="details_data.progress==1">
                <!-- 翻译：推荐原因-->
                <h1>{{ $t("vipRecommend.reasonsForRecommendation") }}</h1>
                <p class="over4" v-html="details_data.introduce_project_reason">
                    <!--{{ details_data.introduce_project_reason }}-->
                </p>
                <div class="btn-group" style="margin-top: 36px;overflow: hidden">
                    <CustomButton @click.native="$refs.interviewDialog.openDialog" class="fl" type="black">
                        {{$t('myInterviewDetail.acceptInvitation')}}<!--接受邀请-->
                    </CustomButton>
                    <CustomButton @click.native="$refs.rejectReason.openDialog" class="fl" style="margin-left: 26px"
                                  type="black">
                        {{$t('myInterviewDetail.declinedInvitation')}}<!--拒绝邀请-->
                    </CustomButton>
                </div>
            </div>
            <!--受邀人拒绝-->
            <div class="state6" v-else-if="details_data.progress==2">
                <h1>{{$t('myPublishedProjects.declinedInvitation')}}<!--您已拒绝--></h1>
                <p>{{$t('myPublishedProjects.reasonInvitation')}}<!--拒绝原因--> ：{{details_data.reject_reason}}</p>
                <h1 style="margin-top: 47px">{{ $t("vipRecommend.reasonsForRecommendation") }}</h1>
                <p class="over4" v-html="details_data.introduce_project_reason">

                </p>
            </div>
            <!--受邀人同意-->
            <div class="state7" v-else-if="details_data.progress==3">
                {{$t('myInterviewDetail.YouHaveBeenWillingToCommunicateForFree')}}<!--您已同意免费邀约-->
            </div>

            <!--受邀人取消预约-->
            <div class="state6" v-if="details_data.progress==4">
                <h1>{{$t('myInterviewDetail.cancelReservation')}}<!--取消预约--></h1>
                <p>{{$t('myProject.interviewTime')}}<!--访谈时间--> ：{{ details_data.invitation_time }}</p>
                <p>{{$t('bulletFrame.reasonForCancellation')}}<!--取消访谈原因--> ：{{ details_data.reject_reason }}</p>
            </div>

            <!--受邀人拒绝-->
            <div class="state6" v-else-if="details_data.progress==6">
                <h1>{{$t('myPublishedProjects.declinedInvitation')}}<!--您已拒绝--></h1>
                <p>{{$t('vipRecommend.reasonsForRefuse')}}：{{details_data.reject_reason}}<!--拒绝原因--></p>
                <h1 style="margin-top: 47px">{{ $t("vipRecommend.reasonsForRecommendation") }}</h1>
                <p class="over4" v-html="details_data.introduce_project_reason">
                    <!--平台推荐原因-->
                </p>
            </div>
            <!--受邀人同意
            <div class="state7" v-else-if="details_data.progress==3">
              您已同意免费邀约1111
            </div>-->


            <!--------------------------您已取消START---------------->
            <div class="state6" v-if="details_data.progress==9">
                <h1>您已取消</h1>
                <p>取消原因：拒绝原因拒绝原因拒绝原因拒绝原因拒绝原因拒绝原因拒绝原因拒绝原因</p>
                <h1 style="margin-top: 47px">{{ $t("vipRecommend.reasonsForRecommendation") }}</h1>
                <p class="over4">
                    平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因
                </p>
            </div>
            <!--------------------------您已取消END------------------->

            <!--------------------------发布者已取消START-------------->
            <div class="state6" v-if="details_data.progress==8">
                <h1>发布者已取消</h1>
                <p>取消原因：拒绝原因拒绝原因拒绝原因拒绝原因拒绝原因拒绝原因拒绝原因拒绝原因</p>
                <h1 style="margin-top: 47px">{{ $t("vipRecommend.reasonsForRecommendation") }}</h1>
                <p class="over4">
                    平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因平台推荐原因
                </p>
            </div>
            <!-------------------------发布者已取消END-------------->

            <!--------------------待受邀人反馈 五选一START---------------->
            <div class="state6" v-if="details_data.progress==5">
                <h1 style="margin-top: 5px">{{ $t("vipRecommend.reasonsForRecommendation") }}</h1>
                <p class="over4" style="margin-bottom: 36px" v-html="details_data.introduce_project_reason">
                    <!--平台推荐原因-->
                </p>
                <AppointmentTime :times5="times5" :type="0"/>
            </div>
            <!--------------------待受邀人反馈 五选一END-------------------->

            <!-----------------待访谈 取消预约START-------------------------------->
            <AppointmentTime :initiatorInfo="details_data" :type="1" v-if="details_data.progress==7"/>
            <!-----------------待访谈 取消预约END-------------------------------->

            <!-----------------评价START-------------------------------->
            <AppointmentTime :initiatorInfo="details_data" :type="4"
                             v-if="details_data.progress==10&&details_data.is_evaluate==0"/>
            <!-----------------评价END-------------------------------->
            <!-----------------评价完成START-------------------------------->
            <AppointmentTime :initiatorInfo="details_data" :type="3"
                             v-if="details_data.progress==10&&details_data.is_evaluate==1"/>
            <!-----------------评价完成END-------------------------------->

            <!--    沟通话题-->
            <div class="talk-topic">
                <h1>{{$t("myPublishedProjects.communTopic")}}<!--沟通话题--></h1>
                <img :src="details_data.project_cover_url" alt=""/>
                <div class="title over">{{ details_data.project_name }}</div>

                <p>
                    {{ details_data.project_intro }}
                </p>
                <!--<div class="source">
                  <span>A</span>
                  <span>轮融资</span>
                </div>-->
            </div>
            <!--      项目详情-->
            <div class="project-detail">
                <h1>{{$t('project.details')}}<!--项目详情--></h1>
                <!--<img src="@/assets/images/rich_content.png" alt="" />-->
                <div style="margin-top: 42px" v-html="details_data.details"></div>
            </div>
        </div>
        <!--弹窗-->
        <InterviewDialog ref="interviewDialog"/>
        <RejectReason ref="rejectReason"/>
    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import CustomButton from "@/components/my_project/vip_private_recommendation/CustomButton";
    import AppointmentTime from "@/components/my_project/my_interview/receive_interview_vip/AppointmentTime";
    import InterviewDialog from "@/components/dialog/InterviewDialog";
    import RejectReason from "@/components/dialog/RejectReason";
    import {followTalent} from "@/api/memberApi";

    import {myInterviewListDetails, inviteDetailsForPrivacy, referralFeedback} from "@/api/myProjectApi";

    export default {
        name: "receiveInterview",
        data() {
            return {
                delivery_data: {},
                details_data: {},
                times5: {},
            };
        },
        computed: {

            appointmentType() {
                switch (this.details_data.progress) {
                    case 7:
                        return 1;
                    default:
                        return '';
                }
            }
        },
        components: {
            BreadcrumbNav,
            CustomButton,
            AppointmentTime,
            InterviewDialog,
            RejectReason,
        },
        props: {},
        mounted() {
            this.delivery_data = this.$route.query;
            this.get_vipprivate();
        },
        methods: {
            //关注
            attention(type, id, name) {
                let formData = {
                    operateType: type,
                    talentId: id,
                    talentName: name,
                    token: localStorage.getItem('token')
                }
                followTalent(formData).then((res) => {
                    if (res.code == 200) {
                        if (type == 1) {
                            this.details_data.followed = 0
                            this.$message.success(this.$t("myProject.followMsg.unfollowSuccess"))
                        } else {
                            this.details_data.followed = 1
                            this.$message.success(this.$t("myProject.followMsg.followSuccess"))
                        }
                    }
                })
            },
            // vip推荐
            async get_vipprivate() {
                let formData = {
                    joinId: this.$route.query.join_id,
                    token: localStorage.getItem("token"),
                };
                let res = await inviteDetailsForPrivacy(formData);
                if (res.code == 200) {
                    this.details_data = res.data;
                    this.times5 = {
                        time1: this.details_data.invite_time1,
                        time2: this.details_data.invite_time2,
                        time3: this.details_data.invite_time3,
                        time4: this.details_data.invite_time4,
                        time5: this.details_data.invite_time5,
                    };
                }
            },
            //平台推荐初次反馈提交
            async goFeedback(feedback, form) {
                let formData = {
                    feedback: feedback,
                    dataType: 2,
                    projectId: this.$route.query.id,
                    rejectReason: form.reason,
                    mobileCode: form.mobileCode,
                    mobileNumber: form.phone,
                    mailBox: form.mail,
                    whatsappCode: form.whatsappCode,
                    whatsApp: form.whatsApp,
                    sourceType: 2,
                    token: localStorage.getItem("token"),
                };
                let res = await referralFeedback(formData);
                if (res.code == 200) {
                    this.$refs.rejectReason.closeDialog();
                    this.get_vipprivate();
                    this.$message.success(res.msg);
                } else {
                    this.$message.error(res.msg);
                }
            },
        },
    };
</script>

<style scoped>
    .state6 h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .state6 p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
        margin-top: 10px;
        word-wrap: break-word;
        word-break: break-all;
    }

    .state7 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .reason h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .reason p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        margin-top: 10px;
    }

    .receiveInterview-container {
        background-color: #ffffff;
    }

    .main {
        width: 1200px;
        padding-top: 14px;
        padding-bottom: 24px;
        margin: 0 auto;
    }

    .people-wrap {
        padding: 38px 0;
        overflow: hidden;
    }

    .people-wrap .head-icon {
        width: 67px;
        height: 67px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .people-info-text {
        margin-left: 13px;
        min-width: 160px;
        max-width: 300px;
    }

    .people-info-text > h1 {
        font-size: 17px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        margin-top: 8px;
    }

    .people-info-text > h2 {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        margin-top: 13px;
        margin-left: 4px;
    }

    .people-wrap .btn-group {
        margin-top: 4px;
        margin-left: 136px;
    }

    hr {
        background-color: #e5e5e5;
    }

    .talk-topic {
        margin-top: 62px;
    }

    .talk-topic > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .talk-topic > img {
        width: 695px;
        height: 460px;
        background: rgba(0, 0, 0, 0);
        border-radius: 4px;
        margin-top: 24px;
    }

    .talk-topic > .title {
        width: 695px;
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 46px;
        color: #000000;
        margin-top: 24px;
    }

    .talk-topic > p {
        width: 468px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 29px;
        color: #000000;
        margin-top: 26px;
    }

    .talk-topic > .source {
        margin-top: 16px;
    }

    .talk-topic > .source > span:first-child {
        font-size: 22px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        vertical-align: bottom;
        margin-right: 9px;
    }

    .talk-topic > .source > span:nth-child(2) {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        vertical-align: bottom;
    }

    .project-detail {
        margin-top: 47px;
    }

    .project-detail > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .project-detail > img {
        margin-top: 42px;

        width: 688px;
    }
</style>
